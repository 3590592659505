import React from 'react';
import './Logout.css';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga';
import Icon, { TYPE_LEAVE } from '../Icon/Icon';

export default function Logout() {
  const { logout } = useAuth0();

  function logoutClick() {
    ReactGA.event({
      category: 'Logout',
      action: 'Click',
      label: 'Logout Button'
    });

    logout({ returnTo: window.location.href });
  }

  return (
    <button className="logout" onClick={logoutClick}>
      <Icon type={TYPE_LEAVE} highlighted={true} />
    </button>
  );
}
