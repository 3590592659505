import React, { useEffect, useState, useCallback } from 'react';
import Call from '../Call/Call';
import './App.css';
import Tray from '../Tray/Tray';
import CallObjectContext from '../../CallObjectContext';
import DailyIframe from '@daily-co/daily-js';
import { logDailyEvent } from '../../logUtils';
import ReactGA from 'react-ga';
import DynetiSocket from '../../DynetiSocket';
import Game from '../Game/Game';
import DynetiSocketContext from '../../DynetiSocketContext';
import { useAuth0 } from '@auth0/auth0-react';
import Rooms from '../Rooms/Rooms';
import Logout from '../Logout/Logout';
import Login from '../Login/Login';
import { callIdFromPageUrl } from '../../agoraUtils';
import InvalidCall from '../ErrorModal/ErrorModal';
import ErrorModal from '../ErrorModal/ErrorModal';

const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';

const { REACT_APP_ENV, REACT_APP_GA_TRACKING_ID } = process.env;

export default function App() {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callObject, setCallObject] = useState(null);
  const [socketId, setSocketId] = useState('');
  const [callId, setCallId] = useState(null);
  const [canJoinCall, setCanJoinCall] = useState(true);
  const [roomId, setRoomId] = useState('');
  const [gameUrl, setGameUrl] = useState('');
  const [gameVisible, setGameVisible] = useState(false);
  const [dynetiSocket, setDynetiSocket] = useState(null);
  const [pendingJoin, setPendingJoin] = useState(null);
  const { isAuthenticated, user } = useAuth0();

  ReactGA.initialize(REACT_APP_GA_TRACKING_ID);

  useEffect(() => {
    if (!dynetiSocket) {
      setDynetiSocket(DynetiSocket.init());
      return;
    }

    // listen for id
    dynetiSocket.on('id', data => {
      setSocketId(data.id);
    });

    // join event
    dynetiSocket.on('join', data => {
      setCanJoinCall(data.canJoin);
      if (!data.canJoin) {
        return;
      }

      ReactGA.set({
        userId: data.userId
      });

      setPendingJoin(data);
    });

    return () => {
      dynetiSocket.disconnect();
    }
  }, [dynetiSocket]);

  useEffect(() => {
    console.log('APP STATE', appState);
    if (pendingJoin) {
      if (appState === STATE_IDLE) {
        startJoiningCall(pendingJoin.url, pendingJoin.username);
        setRoomId(pendingJoin.roomId);
        setPendingJoin(null);
      } else if (appState === STATE_JOINED) {
        startLeavingCall();
      }
    }
  }, [appState, pendingJoin]);

  /**
   * Starts joining an existing call.
   *
   * NOTE: In this demo we show how to completely clean up a call with destroy(),
   * which requires creating a new call object before you can join() again.
   * This isn't strictly necessary, but is good practice when you know you'll
   * be done with the call object for a while and you're no longer listening to its
   * events.
   */
  const startJoiningCall = useCallback((url, username) => {
    const newCallObject = DailyIframe.createCallObject();
    setCallObject(newCallObject);
    setAppState(STATE_JOINING);
    newCallObject.join({url: url, userName: username});
  }, []);

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up

    setGameUrl('');
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      setAppState(STATE_LEAVING);
      callObject.leave();
    }
  }, [callObject, appState]);

  /**
   * Update app state based on reported meeting state changes.
   *
   * NOTE: Here we're showing how to completely clean up a call with destroy().
   * This isn't strictly necessary between join()s, but is good practice when
   * you know you'll be done with the call object for a while and you're no
   * longer listening to its events.
   */
  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error'];

    function handleNewMeetingState(event) {
      event && logDailyEvent(event);
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setCallObject(null);
            setAppState(STATE_IDLE);
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  /**
   * Listen for app messages from other call participants.
   */
  useEffect(() => {
    if (!callObject) {
      return;
    }

    function handleAppMessage(event) {
      if (event) {
        logDailyEvent(event);
        console.log(`received app message from ${event.fromId}: `, event.data);
      }
    }

    callObject.on('app-message', handleAppMessage);

    return function cleanup() {
      callObject.off('app-message', handleAppMessage);
    };
  }, [callObject]);

  /**
   * Show the call UI if we're either joining, already joined, or are showing
   * an error.
   */
  const showCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(
    appState
  );

  /**
   * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
   * or if we've errored out.
   *
   * !!!
   * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
   * can result in unexpected behavior. Disabling the leave call button
   * until then avoids this scenario.
   * !!!
   */
  const enableCallButtons = [STATE_JOINED, STATE_ERROR].includes(appState);

  /**
   * Only enable the start button if we're in an idle state (i.e. not creating,
   * joining, etc.).
   *
   * !!!
   * IMPORTANT: only one call object is meant to be used at a time. Creating a
   * new call object with DailyIframe.createCallObject() *before* your previous
   * callObject.destroy() completely finishes can result in unexpected behavior.
   * Disabling the start button until then avoids that scenario.
   * !!!
   */
  const enableStartButton = appState === STATE_IDLE;

  function handleGameVisible(visible) {
    setGameVisible(visible);
    if (!visible) setGameUrl('');
  }

  useEffect(() => {
    if (isAuthenticated) {
      const callId = callIdFromPageUrl();
      setCallId(callId);
      dynetiSocket.emit('join-call', {
        callHash: callId,
        userId: user.sub,
        nickname: user.nickname
      });
    }
  }, [isAuthenticated]);

  return (
    <div className="app">
      <DynetiSocketContext.Provider value={dynetiSocket}>
        <CallObjectContext.Provider value={callObject}>
          {REACT_APP_ENV === 'dev' ? <div id='socket-id'>DEV Preview - Socket ID: {socketId}</div> : null}
          {isAuthenticated ? (
            callId ? (
              canJoinCall ? (
                <div className="main-container">
                  {showCall ? <Call gameVisible={gameVisible} /> : null}
                  {showCall ? <Game onGameVisible={handleGameVisible} /> : null}
                  <div className="rooms-container">
                    <Rooms disabled={!enableCallButtons} onDisplay={showCall}/>
                  </div>
                  <Tray
                    disabled={!enableCallButtons}
                    onClickLeaveCall={startLeavingCall}
                    roomId={roomId}
                  />
                  <Logout />
                </div>
              ) : (
                <ErrorModal text={"Call ID: " + callId + " doesn't exist."} />
              )
            ) : (
              <ErrorModal text='You are missing a call id in the URL.' />
            )
          ) : (
            <Login />
          )}
        </CallObjectContext.Provider>
      </DynetiSocketContext.Provider>
    </div>
  );
}
