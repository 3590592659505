import React, { useContext, useState, useEffect } from 'react';
import CallObjectContext from '../../CallObjectContext';
import './Game.css';
import DynetiSocketContext from '../../DynetiSocketContext';
import Games from '../Games/Games';
import Tutorial from '../Tutorial/Tutorial';
import ReactGA from 'react-ga';

export default function Game(props) {
  const callObject = useContext(CallObjectContext);
  const dynetiSocket = useContext(DynetiSocketContext);
  const [inputValue, setInputValue] = useState('');
  const [gameUrl, setGameUrl] = useState('');
  const [gameVisible, setGameVisible] = useState(false);
  const [gamesListVisible, setGamesListVisible] = useState(false);
  const [tutorialVisible, setTutorialVisible] = useState(false);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  function toggleGamesList() {
    ReactGA.event({
      category: 'Game',
      action: 'Click',
      label: 'Game Button'
    });

    const visible = !gamesListVisible;
    setInputValue('');
    setGameUrl('');
    setGamesListVisible(visible);
    setGameVisible(false);
  }

  function handleHost() {
    ReactGA.event({
      category: 'Game',
      action: 'Click',
      label: 'Host Button'
    });

    if (inputValue.length === 0) return;
    setGameUrl(inputValue);
  }

  function handleInvite() {
    ReactGA.event({
      category: 'Game',
      action: 'Invite',
      label: inputValue
    });

    if (inputValue.length === 0) return;
    dynetiSocket.emit('game', {url: inputValue});
  }

  function toggleTutorial() {
    ReactGA.event({
      category: 'Game',
      action: 'Click',
      label: 'Help Button'
    });

    const visible = !tutorialVisible;
    setTutorialVisible(visible);
  }

  function handleOpenGame(url) {
    setGameUrl(url);
    setGameVisible(true);
    props.onOpenGame && props.onOpenGame(url);
  }

  useEffect(() => {
    if (!callObject) {
      return;
    }

    dynetiSocket.on('game', data => {
      setGameUrl(data.url);
      setGameVisible(true);
    });

  }, [callObject]);

  useEffect(() => {
    props.onGameVisible && props.onGameVisible(gamesListVisible || gameVisible);
  }, [gamesListVisible, gameVisible]);

  return (
    <div>
      <button className="game-button" onClick={toggleGamesList}>Game</button>
      <button className="game-button game-tutorial" onClick={toggleTutorial}>?</button>
      {gamesListVisible ? (
        <div className="games-container">
          <Games onDisplay={true} onOpenGame={handleOpenGame} />
        </div>
      ) : null}
      {gameVisible ? (
        <div>
          <div className="game-form">
            <input
              id="gameUrlInput"
              className="game-url-input"
              type="text"
              placeholder="Type game url here.."
              value={inputValue}
              onChange={handleChange} />
            <button className="game-button game-host" onClick={handleHost}>Host</button>
            <button className="game-button game-invite" onClick={handleInvite}>Invite</button>
          </div>
          <iframe className="game" src={gameUrl} />
        </div>
      ) : null}
      {tutorialVisible ? (
        <div className="game-tutorial-container">
          <Tutorial onCloseTutorial={toggleTutorial} />
        </div>
      ) : null}
    </div>
  );
}
