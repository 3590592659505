import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import BrowserUnsupported from './components/BrowserUnsupported/BrowserUnsupported';
import { Auth0Provider } from '@auth0/auth0-react';
import DailyIframe from '@daily-co/daily-js';
import { restoreCallId } from './agoraUtils';

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } = process.env;

const onRedirectCallback = (appState) => {
  restoreCallId(appState);
};

ReactDOM.render(
  <Auth0Provider
    domain={REACT_APP_AUTH0_DOMAIN}
    clientId={REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}>
    { DailyIframe.supportedBrowser().supported ? <App /> : <BrowserUnsupported /> }
  </Auth0Provider>,
  document.getElementById('root')
);
