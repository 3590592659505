import React, { useContext, useState, useEffect } from 'react';
import CallObjectContext from '../../CallObjectContext';
import './Games.css';
import DynetiSocketContext from '../../DynetiSocketContext';
import ReactGA from 'react-ga';

export default function Games(props) {
  const callObject = useContext(CallObjectContext);
  const dynetiSocket = useContext(DynetiSocketContext);
  const [gamesList, setGamesList] = useState([]);

  useEffect(() => {
    if (!callObject) {
      return;
    }

    dynetiSocket.on('games', data => {
      setGamesList(data.games);
    });
    dynetiSocket.emit('games', {});

  }, [callObject]);

  function handleGameItem(index) {
    const game = gamesList[index];
    ReactGA.event({
      category: 'Game',
      action: 'Play',
      label: game.id
    });

    console.log('game click', index, game.url);
    props.onOpenGame && props.onOpenGame(game.url);
  }

  return props.onDisplay ? (
    <div className="games">
      <span className="games-header">Games</span>
      <div className="gamesList">
        {gamesList.map((entry, index) => (
          <div key={`entry-${index}`} className="game-item">
            <span className="game-name">{entry.name}</span>
            <span className="game-desc">{entry.description}</span>
            <button className="game-play" onClick={() => handleGameItem(index)}>Play</button>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}
