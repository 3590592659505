export function callIdFromPageUrl() {
  const match = window.location.search.match(/id=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
}

export function storeCallId() {
  clearExistingState();
  const callId = callIdFromPageUrl();
  const stateId = randomString(16);
  storeStateLocally(stateId, callId);
  return stateId;
}

export function restoreCallId(appState) {
  const stateHash = window.localStorage.getItem('stateId');
  if (stateHash === appState) {
    const callId = window.localStorage.getItem(stateHash);
    let url = window.location.origin;
    if (callId && callId !== 'null') {
      url += '?id=' + callId;
    }
    window.history.replaceState(null, null, url);
  }
}

function randomString(length) {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
  let result = ''

  while (length > 0) {
    var bytes = new Uint8Array(16);
    var random = window.crypto.getRandomValues(bytes);

    random.forEach(function(c) {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
}

function storeStateLocally(stateHash, state) {
  window.localStorage.setItem('stateId', stateHash);
  window.localStorage.setItem(stateHash, state);
}

function clearExistingState() {
  const stateHash = window.localStorage.getItem('stateId');
  if (stateHash) {
    window.localStorage.removeItem(stateHash);
    window.localStorage.removeItem('stateId');
  }
}
