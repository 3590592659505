import React, { useState } from 'react';
import './Tutorial.css';
import TutorialPages from '../TutorialPages/TutorialPages';

export default function Tutorial(props) {
  const [tutorialIndex, setTutorialIndex] = useState(0);

  const slides = [
    "Welcome to Agora! Here’s how to get started with games.",
    "1. Select a game by clicking on the play button",
    "2. Create a new game, and  copy the generated invite link",
    "3. Paste the invite link into the text bar at the upper left, then click the invite button. This will automatically open the game window for the other participants",
    "4. Start playing!"
  ];

  function handlePrev() {
    const index = tutorialIndex - 1;
    setTutorialIndex(index);
  }

  function handleNext() {
    const index = tutorialIndex + 1;
    setTutorialIndex(index);
    if (index === slides.length) {
      props.onCloseTutorial && props.onCloseTutorial();
    }
  }

  return (
    <div className="tutorial">
      {slides.map((entry, index) => (
        <span
          key={`entry-${index}`}
          className={tutorialIndex === index ? "tutorial-text" : "tutorial-text hidden"}>
          {entry}
        </span>
      ))}
      <div className="tutorial-buttons">
        <button
          className={tutorialIndex > 0 ? "tutorial-prev" : "tutorial-prev hidden"}
          onClick={handlePrev}>Prev</button>
        <button
          className={tutorialIndex < slides.length ? "tutorial-next" : "tutorial-next hidden"}
          onClick={handleNext}>
          {tutorialIndex === slides.length-1 ? "Done" : "Next"}
        </button>
      </div>
      <div className="tutorial-pages">
        <TutorialPages index={tutorialIndex} count={slides.length} />
      </div>
      <div className="tutorial-close" onClick={props.onCloseTutorial}>X</div>
    </div>
  );
}
