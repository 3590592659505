import React, { useContext, useState, useEffect } from 'react';
import CallObjectContext from '../../CallObjectContext';
import './Rooms.css';
import DynetiSocketContext from '../../DynetiSocketContext';
import ReactGA from 'react-ga';

export default function Rooms(props) {
  const callObject = useContext(CallObjectContext);
  const dynetiSocket = useContext(DynetiSocketContext);
  const [inputValue, setInputValue] = useState('');
  const [roomsInfo, setRoomsInfo] = useState([]);

  function handleRoomJoin(index) {
    const roomId = roomsInfo[index].id;
    const roomName = roomsInfo[index].name;
    ReactGA.event({
      category: 'Room',
      action: 'Join',
      label: roomName + ' | ' + roomId
    });
    dynetiSocket.emit('join-room', {roomId: roomId});
  }

  function handleRoomDelete(index) {
    const roomId = roomsInfo[index].id;
    const roomName = roomsInfo[index].name;
    ReactGA.event({
      category: 'Room',
      action: 'Delete',
      label: roomName + ' | ' + roomId
    });
    dynetiSocket.emit('delete-room', { roomId: roomId });
  }

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    dynetiSocket.emit('add-room', {room: inputValue});
    setInputValue('');
  }

  useEffect(() => {
    if (!callObject) {
      return;
    }

    dynetiSocket.on('rooms', data => {
      setRoomsInfo(data);
    });
    dynetiSocket.emit('rooms', {});

  }, [callObject]);

  return props.onDisplay ? (
    <div className={props.disabled ? "rooms disabled" : "rooms"}>
      <span className="rooms-header">Rooms</span>
      <div className="roomsList">
        {roomsInfo.map((room, index) => (
          <div key={`entry-${index}`} className="room">
            <div className="room-item">
              <button
                className={room.canDelete ? "room-delete" : "room-delete hidden"}
                onClick={() => handleRoomDelete(index)}>X</button>
              <span className="room-name">{room.name}</span>
              <button className="room-join" onClick={() => handleRoomJoin(index)}>Join</button>
            </div>
            {room.participants.map((participant, pIndex) => (
              <div key={`entry-${index}-${pIndex}`} className="participant">
                <span>{participant.nickname}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="roomsInput"></label>
        <input
          id="roomsInput"
          className="rooms-input"
          type="text"
          placeholder="Type room name here.."
          value={inputValue}
          onChange={handleChange}
        ></input>
        <button type="submit" className="create-room-button">
          Create
        </button>
      </form>
    </div>
  ) : null;
}
