import React, { useContext, useState, useEffect, useRef } from 'react';
import CallObjectContext from '../../CallObjectContext';
import './Sidebar.css';
import Chat from '../Chat/Chat';

export default function Sidebar(props) {
  const callObject = useContext(CallObjectContext);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    console.log('tab clicked', tabIndex);
  }, [tabIndex]);

  function handleChat() {
    props.notification && props.notification();
  }

  return props.onClickDisplay ? (
    <div className="sidebar">
      <div className="sidebar-tabs">
        {/*<button className={tabIndex === 0 ? "tab-button active" : "tab-button"} onClick={() => setTabIndex(0)}>Chat</button>*/}
        {/*<button className={tabIndex === 1 ? "tab-button active" : "tab-button"} onClick={() => setTabIndex(1)}>Games</button>*/}
      </div>
      <div className={props.disabled ? "sidebar-content disabled" : "sidebar-content"}>
        <Chat onDisplay={true} notification={handleChat} roomId={props.roomId} />
      </div>
      <div className="sidebar-close" onClick={props.onClose}>X</div>
    </div>
  ) : null;
}
