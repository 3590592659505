import React from 'react';
import './ErrorModal.css';

export default function ErrorModal(props) {

  return (
    <div className="error-modal">
      <span>{props.text}</span>
    </div>
  );
}
