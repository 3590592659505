import React, { useContext, useState, useEffect, useRef } from 'react';
import CallObjectContext from '../../CallObjectContext';
import './Chat.css';
import ReactGA from 'react-ga';

export default function Chat(props) {
  const callObject = useContext(CallObjectContext);
  const [inputValue, setInputValue] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const chatRef = useRef();

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  function handleSubmit(event) {
    ReactGA.event({
      category: 'Chat',
      action: 'Sent',
      label: 'RoomId: ' + props.roomId
    });

    event.preventDefault();
    callObject.sendAppMessage({ message: inputValue }, '*');
    console.log('username', callObject.participants().local.user_name);
    const name = callObject.participants().local.user_name
      ? callObject.participants().local.user_name
      : 'Guest';
    setChatHistory([
      ...chatHistory,
      {
        sender: name,
        message: inputValue,
      },
    ]);
    setInputValue('');
  }

  /**
   * Update chat state based on a message received to all participants.
   *
   */
  useEffect(() => {
    if (!callObject) {
      return;
    }

    function handleAppMessage(event) {
      const participants = callObject.participants();
      const name = participants[event.fromId].user_name
        ? participants[event.fromId].user_name
        : 'Guest';
      setChatHistory([
        ...chatHistory,
        {
          sender: name,
          message: event.data.message,
        },
      ]);
      // Make other icons light up
      props.notification();
    }

    callObject.on('app-message', handleAppMessage);

    return function cleanup() {
      callObject.off('app-message', handleAppMessage);
    };
  }, [callObject, chatHistory]);

  useEffect(() => {
    if (!chatRef.current) return;
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatHistory]);

  return (
    <div className={props.onDisplay ? "chat" : "chat hidden"}>
      <span className="chat-header">Chat</span>
      <div ref={chatRef} className="messageList">
        {chatHistory.map((entry, index) => (
          <div key={`entry-${index}`} className="message">
            <b>{entry.sender}</b>: {entry.message}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <label htmlFor='chatInput'/>
        <input
          id='chatInput'
          className='chat-input'
          type='text'
          placeholder='Type your message here..'
          value={inputValue}
          onChange={handleChange} />
        <button type="submit" className="send-chat-button">
          Send
        </button>
      </form>
    </div>
  );
}
