import React from 'react';
import './Login.css';
import { useAuth0 } from '@auth0/auth0-react';
import { storeCallId } from '../../agoraUtils';

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  function login() {
    const stateId = storeCallId();
    loginWithRedirect({appState: stateId});
  }

  return (
    <div className="login">
      <span>Please login</span>
      <button onClick={login}>Login</button>
    </div>
  );
}
