import React, { useState } from 'react';
import './TutorialPages.css';

export default function TutorialPages(props) {

  const pagesClasses = getPages();

  function getPages() {
    var pagesClasses = [];
    for (let i = 0; i < props.count; i++) {
      pagesClasses.push(props.index === i ? 'tutorial-page selected' : 'tutorial-page');
    }
    return pagesClasses;
  }

  return (
    <div className="tutorial-pages-list">
      {pagesClasses.map((entry, index) => (
        <div key={`entry-${index}`} className={entry} />
      ))}
    </div>
  );
}
